import React, { createContext, useState } from 'react'
import axios from 'axios'

export const MyData = createContext()

function Context({children}) {
    const [allUserData, setAllUserData] = useState([])
    const getAllUserFn = ()=>{
        axios.get("https://sratebackend-2.onrender.com/signup").then((res)=> setAllUserData(res.data))
    }

    const [allPlanData, setAllPlanData] = useState([])
    const getAllPlanDataFn = ()=>{
        axios.get('https://sratebackend-2.onrender.com/plan').then((res)=> setAllPlanData(res.data.data))
    }
    const deleteUserFn = async (userId) => {
      try {
          await axios.delete(`https://sratebackend-2.onrender.com/signup/${userId}`);
          getAllUserFn(); // Refresh the user list after deletion
      } catch (error) {
          console.error("Error deleting user:", error);
      }
  }

  return (
    <MyData.Provider value={{allUserData, setAllUserData, getAllUserFn, allPlanData, setAllPlanData, getAllPlanDataFn, deleteUserFn }}>
        {children}
    </MyData.Provider>
  )
}

export default Context