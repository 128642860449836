import React, { useContext, useEffect } from 'react';
import { MyData } from './Context';

const Dashboard = () => {
  useEffect(()=> {getAllUserFn(); getAllPlanDataFn()}, [])
  const {allUserData, setAllUserData, getAllUserFn, allPlanData, setAllPlanData, getAllPlanDataFn,deleteUserFn} = useContext(MyData)
  const userCount = 150; // Example user count
  const totalInvested = 500000; // Example total amount invested
  const totalTransactions = 200; // Example transaction count
  const recentTransactions = [
    { id: 1, user: 'John Doe', amount: 1000, type: 'Deposit', date: '2023-10-01' },
    { id: 2, user: 'Jane Smith', amount: 500, type: 'Withdrawal', date: '2023-10-02' },
    // Add more recent transactions here
  ];
  const handleDeleteUser = (userId) => {
    if(window.confirm('Are you sure you want to delete this user?')) {
        deleteUserFn(userId);
    }
}
  return (
    <div className="p-6 bg-gray-100 h-full">
      <h1 className="text-3xl font-bold mb-4 text-center">Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
        <div className="bg-white rounded-lg p-4 shadow-md">
          <h2 className="text-xl font-semibold">Total Users</h2>
          <p className="text-3xl font-bold">{allUserData.length}</p>
        </div>
        <div className="bg-white rounded-lg p-4 shadow-md">
          <h2 className="text-xl font-semibold">Total Invested</h2>
          <p className="text-3xl font-bold">₹{totalInvested}</p>
        </div>
        <div className="bg-white rounded-lg p-4 shadow-md">
          <h2 className="text-xl font-semibold">Total Transactions</h2>
          <p className="text-3xl font-bold">{totalTransactions}</p>
        </div>
        <div className="bg-white rounded-lg p-4 shadow-md">
          <h2 className="text-xl font-semibold">Total Plans</h2>
          <p className="text-3xl font-bold">{allPlanData.length}</p>
        </div>
      </div>

      <h2 className="text-2xl font-bold mb-4">Recent Transactions</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg shadow-lg">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-3 px-4 text-left">Transaction ID</th>
              <th className="py-3 px-4 text-left">User</th>
              <th className="py-3 px-4 text-left">Amount</th>
              <th className="py-3 px-4 text-left">Type</th>
              <th className="py-3 px-4 text-left">Date</th>
            </tr>
          </thead>
          <tbody>
            {recentTransactions.map((transaction) => (
              <tr key={transaction.id} className="hover:bg-gray-100">
                <td className="py-2 px-4 border">{transaction.id}</td>
                <td className="py-2 px-4 border">{transaction.user}</td>
                <td className="py-2 px-4 border">₹{transaction.amount}</td>
                <td className="py-2 px-4 border">{transaction.type}</td>
                <td className="py-2 px-4 border">{transaction.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <h2 className="text-2xl font-bold mt-8 mb-4">Recent Users</h2>
      <div className="overflow-x-auto">
      <table className="min-w-full bg-white rounded-lg shadow-lg">
            <thead className="bg-gray-200">
                <tr>
                    <th className="py-3 px-4 text-left">User ID</th>
                    <th className="py-3 px-4 text-left">Name</th>
                    <th className="py-3 px-4 text-left">Email</th>
                    <th className="py-3 px-4 text-left">Registration Date</th>
                    <th className="py-3 px-4 text-left">Actions</th> {/* New column */}
                </tr>
            </thead>
            <tbody>
                {allUserData.map((user, index) => (
                    <tr key={user._id} className="hover:bg-gray-100">
                        <td className="py-2 px-4 border">{index+1}</td>
                        <td className="py-2 px-4 border">{user.firstName} {user.lastName}</td>
                        <td className="py-2 px-4 border">{user.email}</td>
                        <td className="py-2 px-4 border">{user.phoneNumber}</td>
                        <td className="py-2 px-4 border">
                            <button 
                                onClick={() => handleDeleteUser(user._id)}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>

      </div>
    </div>
  );
};

export default Dashboard;
