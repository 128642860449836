import React, { useState } from 'react';
import { FaBell, FaUserCircle } from 'react-icons/fa';

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const notifications = [
    { id: 1, message: "Investment plan updated successfully." },
    { id: 2, message: "User John Doe has been verified." },
    { id: 3, message: "New transaction has been added." },
  ];

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <header className="flex justify-between items-center bg-gray-800 text-white p-4 shadow-md">
      <div className="flex items-center">
        <h1 className="text-2xl font-bold">Admin Panel</h1>
      </div>
      <div className="flex items-center space-x-6">
        <FaBell className="text-xl cursor-pointer hover:text-gray-400" onClick={toggleModal} />
        <FaUserCircle className="text-xl cursor-pointer hover:text-gray-400" />
      </div>

      {/* Notification Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-4 max-w-lg mx-auto">
            <h2 className="text-xl font-bold mb-4">Notifications</h2>
            <ul>
              {notifications.map((notification) => (
                <li key={notification.id} className="py-2 border-b">
                  {notification.message}
                </li>
              ))}
            </ul>
            <button onClick={toggleModal} className="mt-4 bg-red-500 text-white p-2 rounded-md hover:bg-red-600 transition duration-300">
              Close
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
