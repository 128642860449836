import React, { useContext, useEffect, useState } from "react";
import { MyData } from "./Context";
import axios from "axios";

const UserManagement = () => {
  useEffect(() => {
    getAllUserFn();
    getAllPlanDataFn();
  }, []);

  const {
    allUserData,
    getAllUserFn,
    allPlanData,
    getAllPlanDataFn,
  } = useContext(MyData);

  const [isInvestmentModalOpen, setIsInvestmentModalOpen] = useState(false);
  const [isBankDetailsModalOpen, setIsBankDetailsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [amount, setAmount] = useState(0);
  const [investmentDate, setInvestmentDate] = useState("");

  const openInvestmentModal = (user) => {
    setSelectedUser(user);
    setIsInvestmentModalOpen(true);
  };

  const openBankDetailsModal = (user) => {
    setSelectedUser(user);
    setIsBankDetailsModalOpen(true);
  };

  const closeModal = () => {
    setIsInvestmentModalOpen(false);
    setIsBankDetailsModalOpen(false);
    setSelectedUser(null);
    setAmount("");
    setInvestmentDate("");
  };

  const handleApprove = async (user) => {
    try {
      const planName =
        amount >= 2000 && amount <= 59999
          ? "Primary"
          : amount >= 60000 && amount <= 299999
          ? "Intermediate"
          : "Advance";

      const planData = allPlanData.find((i) => i.planName === planName);
      const monthlyProfit = amount * (planData.monthlyReturn / 100);

      // alert(JSON.stringify( {
      //          planId: planData,
      //          amount,
      //          investmentDate: investmentDate || new Date(),
      //          lastProfitUpdate: new Date(),
      //          dailyProfit: (monthlyProfit / 30).toFixed(2),
      //          totalProfit: 0,
      //          monthlyProfit: 0,
      //          userId: user._id,
      //       }))

      await axios.put(`https://sratebackend-2.onrender.com/signup/${user._id}`, {
        investments: [
          {
            planId: planData,
            amount,
            investmentDate: investmentDate || new Date(),
            lastProfitUpdate: new Date(),
            dailyProfit: (monthlyProfit / 30).toFixed(2),
            totalProfit: 0,
            monthlyProfit: 0,
            userId: user._id,
          },
        ],
      });

      getAllUserFn();
      closeModal();
      alert("Investment Added Successfully!");
    } catch (err) {
      console.error("Failed to add investment:", err);
      alert("Failed to add investment. Please try again.");
    }
  };

  return (
    <div className="p-6 bg-gray-100 h-full">
      <h1 className="text-3xl font-bold mb-4">User Management</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg shadow-lg">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-3 px-4 text-left">User ID</th>
              <th className="py-3 px-4 text-left">First Name</th>
              <th className="py-3 px-4 text-left">Last Name</th>
              <th className="py-3 px-4 text-left">Email</th>
              <th className="py-3 px-4 text-left">Phone</th>
              <th className="py-3 px-4 text-left">KYC Status</th>
              <th className="py-3 px-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {allUserData.map((user, index) => (
              <tr key={user.id} className="hover:bg-gray-100">
                <td className="py-2 px-4 border">{index + 1}</td>
                <td className="py-2 px-4 border">{user.firstName}</td>
                <td className="py-2 px-4 border">{user.lastName}</td>
                <td className="py-2 px-4 border">{user.email}</td>
                <td className="py-2 px-4 border">{user.phoneNumber}</td>
                <td className="py-2 px-4 border">
                  {user.kycVerified ? "Verified" : "Pending"}
                </td>
                <td className="py-2 px-4 border flex">
                  <button
                    onClick={() => openInvestmentModal(user)}
                    className="bg-green-500 text-white p-1 rounded-md hover:bg-green-600 transition duration-300 mr-2"
                  >
                    Add Investment
                  </button>
                  <button
                    onClick={() => openBankDetailsModal(user)}
                    className="bg-blue-500 text-white p-1 rounded-md hover:bg-blue-600 transition duration-300"
                  >
                    View Bank Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Investment Modal */}
      {isInvestmentModalOpen && selectedUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4">
              Add Investment for {selectedUser.firstName} {selectedUser.lastName}
            </h2>
            <div className="space-y-2">
              <label className="block text-sm font-medium">Amount:</label>
              <input
                type="number"
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="border p-2 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />

              <label className="block text-sm font-medium">Investment Date:</label>
              <input
                type="date"
                name="investmentDate"
                value={investmentDate}
                onChange={(e) => setInvestmentDate(e.target.value)}
                className="border p-2 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mt-4 text-right">
              <button
                onClick={closeModal}
                className="bg-red-500 text-white p-2 rounded-md hover:bg-red-600 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() => handleApprove(selectedUser)}
                className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300"
              >
                Add Investment
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Bank Details Modal */}
      {isBankDetailsModalOpen && selectedUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4">
              Bank Details for {selectedUser.firstName} {selectedUser.lastName}
            </h2>
            <div className="space-y-2">
              <p>
                <strong>Account Number:</strong>{" "}
                {selectedUser.bankDetails?.accountNumber || "N/A"}
              </p>
              <p>
                <strong>IFSC Code:</strong>{" "}
                {selectedUser.bankDetails?.ifscCode || "N/A"}
              </p>
            </div>
            <div className="mt-4 text-right">
              <button
                onClick={closeModal}
                className="bg-red-500 text-white p-2 rounded-md hover:bg-red-600 transition duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagement;
