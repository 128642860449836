import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { MyData } from "./Context";

const TransactionVerification = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const {allUserData, setAllUserData, getAllUserFn,allPlanData, setAllPlanData, getAllPlanDataFn} = useContext(MyData)

  // Fetch transactions from backend
  const fetchTransactions = async () => {
    try {
      const response = await axios.get("https://sratebackend-2.onrender.com/signup");
      setTransactions(response.data.map((i)=> i.transactions));
    } catch (err) {
      setError("Failed to fetch transactions. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    
    fetchTransactions();
    getAllUserFn();
    getAllPlanDataFn()
  }, []);

  // Approve transaction
  const handleApprove = async (transaction) => {
    try {
      const planName = await transaction.amount >= 2000 & transaction.amount <= 59999 ? "Primary" :  transaction.amount >= 60000 & transaction.amount <= 299999 ? "Intermediate" : "Advance"
      const userData = await allUserData.filter((i)=> i._id == transaction.userId)[0]
      const planData = await allPlanData.filter((i)=> i.planName == planName)[0]
      const yearlyProfit = await transaction.amount * planData.monthlyReturn / (100*30)
     

      await axios.put(`https://sratebackend-2.onrender.com/signup/${transaction.userId}`, {
        investments: [{
          planId:  planData,
          amount: transaction.amount, // Amount invested
          investmentDate: Date.now(), // Date of investment
          lastProfitUpdate: Date.now(), // When profit was last updated
          dailyProfit: parseFloat(yearlyProfit.toFixed(2)), // Profit per day
          totalProfit: 0, // Accumulated profit
          monthlyProfit : 0,
          userId : transaction.userId

        }],
        transactions : [{
          ...userData.transactions[0], status : "Completed"
        }]

      });
      fetchTransactions()
      alert("Transaction Approved!");
      // setTransactions((prev) =>
      //   prev.map((transaction) =>
      //     transaction.transactionId === transactionId
      //       ? { ...transaction, status: "Completed" }
      //       : transaction
      //   )
      // );
    } catch (err) {
      alert("Failed to approve transaction. Please try again.");
    }
  };

  // Reject transaction
  const handleReject = async (transactionId) => {
    const reason = prompt("Enter the reason for rejection:");
    if (!reason) {
      alert("Rejection reason is required.");
      return;
    }

    try {
      await axios.put(`https://your-backend-url.com/api/transactions/${transactionId}`, {
        status: "Failed",
        comments: reason,
      });
      setTransactions((prev) =>
        prev.map((transaction) =>
          transaction.transactionId === transactionId
            ? { ...transaction, status: "Failed" }
            : transaction
        )
      );
    } catch (err) {
      alert("Failed to reject transaction. Please try again.");
    }
  };

  if (loading) {
    return <div>Loading transactions...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">Transaction Verification</h1>
     
      <div className="overflow-x-auto bg-white rounded-lg shadow-lg">
        <table className="min-w-full text-sm">
          <thead className="bg-gray-800 text-white sticky top-0">
            <tr>
              {/* <th className="py-3 px-4 text-left">Transaction ID</th> */}
              <th className="py-3 px-4 text-left">Name</th>
              <th className="py-3 px-4 text-left">Amount</th>
              <th className="py-3 px-4 text-left">Status</th>
              <th className="py-3 px-4 text-left">UTR Number</th>
              <th className="py-3 px-4 text-left">Screenshot</th>
              <th className="py-3 px-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {transactions.flat().map((transaction, index) => (
              <tr
                // key={transaction.transactionId}
                className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"} hover:bg-gray-100`}
              >
                {/* <td className="py-2 px-4 border">{transaction.userId}</td> */}
                <td className="py-2 px-4 border">{transaction.name}</td>
                <td className="py-2 px-4 border">₹{transaction.amount}</td>
                <td className="py-2 px-4 border">
                  <span
                    className={`px-3 py-1 rounded-full text-sm font-medium ${
                      transaction.status === "Completed"
                        ? "bg-green-200 text-green-800"
                        : transaction.status === "Pending"
                        ? "bg-yellow-200 text-yellow-800"
                        : "bg-red-200 text-red-800"
                    }`}
                  >
                    {transaction.status}
                  </span>
                </td>
                <td className="py-2 px-4 border">{transaction.utrNumber || "N/A"}</td>
                <td className="py-2 px-4 border">
                  {transaction.paymentScreenshot ? (
                    <a
                      href={transaction.paymentScreenshot}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      View Screenshot
                    </a>
                  ) : (
                    "No Screenshot"
                  )}
                </td>
                <td className="py-2 px-4 border flex gap-2">
                  {transaction.status === "Pending" && (
                    <>
                      <button
                        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                        onClick={() => handleApprove(transaction)}
                      >
                        Approve
                      </button>
                      <button
                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                        onClick={() => handleReject(transaction.transactionId)}
                      >
                        Reject
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionVerification;
