


import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import UserManagement from './pages/UserManagement';
import InvestmentManagement from './pages/InvestmentManagement';
import TransactionManagement from './pages/TransactionManagement';
import Plan from './pages/Plan';
import KYCVerification from './pages/KYCVerification';
import Context from './pages/Context';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import ReferralPage from './pages/ReferralPage';

const Layout = ({ children }) => {
  const location = useLocation();
  const showHeaderAndSidebar = location.pathname !== '/' && location.pathname !== '/login';

  return (
    <div className="flex flex-col h-screen">
      {showHeaderAndSidebar && <Header />}
      <div className="flex flex-grow">
        {showHeaderAndSidebar && <Sidebar />}
        <main className="flex-grow p-6 bg-gray-100 overflow-auto">{children}</main>
      </div>
    </div>
  );
};

function App() {
  return (
    <Context>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/*"
            element={
              <Layout>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/users" element={<UserManagement />} />
                  <Route path="/investments" element={<InvestmentManagement />} />
                  <Route path="/transactions" element={<TransactionManagement />} />
                  <Route path="/plan" element={<Plan />} />
                  <Route path="/kyc-verification" element={<KYCVerification />} />
                  <Route path="/referral" element={<ReferralPage />} />
                </Routes>
              </Layout>
            }
          />
        </Routes>
      </Router>
    </Context>
  );
}

export default App;
