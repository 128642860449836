import React from 'react';
import { FaUser, FaChartLine, FaDollarSign, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
   const navigate = useNavigate()
  return (
    <div className="bg-gray-800 text-white h-full w-64 p-4">
    
      <ul className="space-y-2">
        <li onClick={()=> navigate("/dashboard")} className="flex items-center p-2 hover:bg-gray-700 rounded transition duration-200">
          <FaChartLine className="mr-2" />
          <span>Dashboard</span>
        </li>
        <li onClick={()=> navigate("/plan")} className="flex items-center p-2 hover:bg-gray-700 rounded transition duration-200">
          <FaChartLine className="mr-2" />
          <span>Manage Plan</span>
        </li>
        <li onClick={()=> navigate("/users")} className="flex cursor-pointer items-center p-2 hover:bg-gray-700 rounded transition duration-200">
          <FaUser className="mr-2" />
          <span>User Management</span>
        </li>
        <li onClick={()=> navigate("/kyc-verification")} className="flex cursor-pointer items-center p-2 hover:bg-gray-700 rounded transition duration-200">
          <FaUser className="mr-2" />
          <span>KYC Management</span>
        </li>
        <li onClick={()=> navigate("/transactions")} className="flex items-center p-2 hover:bg-gray-700 rounded transition duration-200">
          <FaDollarSign className="mr-2" />
          <span>Transactions</span>
        </li>
        <li onClick={()=> navigate("/investments")} className="flex items-center p-2 hover:bg-gray-700 rounded transition duration-200">
          <FaDollarSign className="mr-2" />
          <span>Investment Management</span>
        </li>
        <li onClick={()=> navigate("/referral")} className="flex items-center p-2 hover:bg-gray-700 rounded transition duration-200">
          <FaDollarSign className="mr-2" />
          <span>Referral</span>
        </li>
        <li className="flex items-center p-2 hover:bg-gray-700 rounded transition duration-200">
          <FaSignOutAlt className="mr-2" />
          <span>Logout</span>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
